import React, {useState, useCallback, Fragment} from 'react'
import {
  LegacyCard,
  FormLayout,
  InlineError,
  TextField,
  LegacyStack,
  Button,
  ButtonGroup,
  Modal,
  ChoiceList,
  Thumbnail,
  Text,
  Box,
  Divider,
  Bleed,
} from "@shopify/polaris";
import ProductPickerModal from '../../../../../components/productPickerModal'
import selectPayloadToPPData from '../../../../../helpers/selectPayloadToPPData'
import update from 'immutability-helper'
import styled from 'styled-components'
import { DISCOUNT_TYPES } from '../../../../../constants/constants'
import CardWrapper from '../../../../../components/cardWrapper'
import CardDivider from '../../../../../components/cardDivider';
const CouponWrapper = styled.div`
  .coupon-title {
    display: block;
    padding: 5px 0;
  }
  .coupon-items {
    display: flex;
    flex-direction: row;
    gap: 15px;
    .coupon-item:first-of-type {
      flex: 0 0 auto;
    }
    .coupon-item:nth-child(2) {
      width: 125px;
    }
    .coupon-item:last-of-type {
      flex: 1 1 auto;
    }
  }
`
const EventTriggerWrapper = styled.div`
    .trigger-title {
        margin-bottom: 10px;
    }
    .trigger-button-group {
        display: flex ;
        gap: 10px;
        flex-direction: column;
        .active {
          border: 2px solid #008060;
          border-radius: 6px;
        }
    }
`
const FeaturedImageWrapper = styled.div`
  .featued-image-title {
    display: block;
    padding-bottom: 5px;
  }
  .Polaris-Stack__Item:last-of-type {
    display: flex;
    align-items: center
  }
`
const EVENT_TYPES = [
  {
    label: 'Checkout (cart page)',
    value: 'cart',
    helpText: 'Offer is displayed when the buyer clicks ‘Checkout’ on cart page.'
  },
  {
    label: 'Product (product page)',
    value: 'products',
    helpText: 'Offer is displayed when buyer clicks "Add to Cart" on product page.'
  },
];
function CartProductsSetting(props) {
  const { host, shop, token, state, setState, productPickerShow, error, setError, currency, currencySymbol } = props
  const [productPickerVisible, setProductPickerVisible] = useState(false)
  const [active, setActive] = useState(false);
  const handleModalChange = useCallback(() => setActive(!active), [active]);
  const activator = <Button onClick={handleModalChange}>Change image</Button>;
  const [selectedImage, setSelectedImage] = useState([]);
  const handleImageChange = useCallback((value) => setSelectedImage(value), []);
  const updateFeaturedImage = () => {
    if(selectedImage.length > 0){
      setState(update(state, { featuredImage: { $set: selectedImage[0] }}))
    }
    handleModalChange()
  }

  const [selectedType, setSelectedType] = useState(!state.discount.sym || state.discount.sym === currencySymbol ? 'fixedAmount' : 'percentage');
  const [eventType, setEventType] = useState(state.triggerEvent || 'cart');
  const handleDiscountTypeChange = (type, index) => {
    const discount = {sym: type === 'percentage' ? '%' : currencySymbol, value: ''}
      if (Array.isArray(state.product)) {
        setState(update(state,{ product: {[index] :{ discount: { $set: discount }}}}))
      } else {
        if ((type === 'percentage' && "%" === state?.discount?.sym) || (type === 'fixedAmount' && currencySymbol === state?.discount?.sym) ) return;
        setState(update(state, { discount: { $set: discount }}))
      }
      setSelectedType(type);
  }

  const handleTriggerEventClick = (triggerEvent) => {
    setState(update(state,{ triggerEvent: {$set: triggerEvent }}))
    setEventType(triggerEvent)
  }
  return (
    <CardWrapper title="Product" subtitle="Discounts, Trigger Event, Featured Image">
        <FormLayout>
          <Fragment>
            <div className='mockInputContainer'>
              <p className='mockInputLabel' style={{paddingTop:'10px'}}>Offer product</p>
              <div
                className='mockInput'
                onClick={() => setProductPickerVisible(true)}
              >
                {
                  ((!state.variant && !state.variants) || !state.product) ?
                    <span>Select a product</span> :
                    <div>
                      <img
                        src={state.product.image}
                        className='mockInputImage'
                      />
                      {state.variants ?
                        <span>{state.product.title} | {state.variants.map(variant => variant.title).join("/")}</span>
                        :
                        <span>{state.product.title} | {state.variant.title}</span>
                      }
                    </div>
                }
              </div>
              {
                error && error.product ?
                  <InlineError message={error.product} fieldID="product-error" />
                  :
                  false
              }
            </div>
            { "product" === productPickerShow ?
              <ProductPickerModal
                host={host}
                shop={shop}
                token={token}
                open={productPickerVisible}
                onSelect={(selectPayload) => {
                  const data = selectPayloadToPPData(selectPayload)
                  if (!data) {
                    if (state.product) {
                      // Clear selection if one exists
                      setState(update(state, {
                        product: { $set: null },
                        variant: { $set: null },
                        variants: { $set: null },
                      }))
                    }
                    return
                  }

                  setState(update(state, {
                    product: { $set: data.product },
                    variants: { $set: data.variants },
                    featuredImage: { $set: data.product?.images[0]?.originalSrc}
                  }))
                  setError(null)
                }}
                onClose={() => setProductPickerVisible(false)}
                initialProduct={state?.product ? {product:state.product, variants: state.variants}:null || null}
              />
              :
              false
            }
          </Fragment>
          <CardDivider />
          <CouponWrapper>
            <LegacyCard sectioned title="Discounts">
              <Bleed marginInline="400">
                  <Box paddingBlockEnd="400">
                      <Divider borderColor="border"/>
                  </Box>
              </Bleed>
              <LegacyStack vertical={true}>
                <ChoiceList
                    title="Type"
                    choices={DISCOUNT_TYPES}
                    selected={selectedType}
                    onChange={(value) => handleDiscountTypeChange(value[0], 0)}
                  />
                  <TextField
                    onChange={(val) => {
                      const newVal = "percentage" === selectedType && val > 100 ? 100 : val
                      setState(update(state,  { discount: { $set: {sym:"fixedAmount" === selectedType ? currencySymbol : '%', value:newVal} }}))
                    }}
                    value={state.discount.sym ? String(state.discount.value): state.discount}
                    min={0}
                    max={"percentage" === selectedType ? 100 : null}
                    type={'text'}
                    suffix={"fixedAmount" === selectedType ? currency:'%'}
                  />
              </LegacyStack>
            </LegacyCard>
          </CouponWrapper>
          <CardDivider />
          <ChoiceList
            title="Trigger event"
            choices={EVENT_TYPES}
            selected={eventType}
            onChange={(value) => handleTriggerEventClick(value[0])}
          />
          <CardDivider />
          <FeaturedImageWrapper>
            <span className='featued-image-title'>Featured image</span>
                <LegacyStack>
                  <LegacyStack.Item fill>
                    <img
                      style={{
                        width: 60,
                        height: 60,
                        objectFit: "contain",
                        objectPosition: "center",
                        border: '1px solid #AEB4B9',
                        borderRadius: '5px'
                      }}
                      src={state.featuredImage && state?.product?.id ? state.featuredImage : 'https://firebasestorage.googleapis.com/v0/b/upsell-shopify.appspot.com/o/200.png?alt=media&token=2cf839eb-05e5-49b1-a591-4fc02e78e94b'}
                    />
                  </LegacyStack.Item>
                  <LegacyStack.Item style={{display:'flex'}} className="test">
                    {
                      state.product &&
                      <Modal
                        activator={activator}
                        open={active}
                        onClose={handleModalChange}
                        title="Images"
                        primaryAction={{
                          content: 'Add image',
                          onAction: updateFeaturedImage,
                        }}
                        secondaryActions={[
                          {
                            content: 'Cancel',
                            onAction: handleModalChange,
                          },
                        ]}
                      >
                        <Modal.Section>

                          <ChoiceList
                            choices={state.product.images ? state.product.images.map(image =>
                            {
                              return {
                                label:
                                  <Thumbnail
                                    source={image.originalSrc}
                                  />,
                                value: image.originalSrc}
                            }): {label:
                                <Thumbnail
                                  source={state.product.image}
                                />,
                              value: state.product.image}}
                            selected={selectedImage}
                            onChange={handleImageChange}
                          />

                        </Modal.Section>
                      </Modal>
                    }
                  </LegacyStack.Item>
                </LegacyStack>
                { !state.product && <Text as="p">Select a product in order to view featured image</Text> }
          </FeaturedImageWrapper>
        </FormLayout>
    </CardWrapper>
  );
}

export default CartProductsSetting;